<template>
  <div :class="$style.wrap" ref="wrap">
    <div>
      <Back />
      <div :class="$style.content">
        <img :class="$style.logo" src="@/assets/img/logo.png" />
        <div :class="$style.filedWrap">
          <p :class="$style.label">手机号</p>
          <van-field
            :class="$style.field"
            v-model="mobile"
            type="tel"
            maxlength="11"
            placeholder="请输入手机号"
          />
        </div>
        <div :class="$style.filedWrap">
          <p :class="$style.label">密码</p>
          <van-field
            :class="$style.field"
            v-model="pwd"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <van-button
          :class="$style.btn"
          round
          block
          @click="handleLogin"
          :color="canSubmit ? '#212121' : '#707070'"
        >
          登录</van-button
        >
        <div :class="$style.actionWrap">
          <span :class="$style.register" @click="handle2Register"
            >注册新用户</span
          >
          <span @click="handle2ForgetPwd">忘记密码？</span>
        </div>
      </div>
    </div>

    <div :class="$style.clause">
      <van-checkbox v-model="checked" :class="$style.checked"
        >我已同意
        <span :class="$style.clauseItem" @click.stop="handle2Agreement"
          >《用户协议》</span
        >和
        <span :class="$style.clauseItem" @click.stop="handle2Privacy"
          >《隐私政策》</span
        >
      </van-checkbox>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import { md5, setStorage } from "@/utils/util";
import { login } from "@/api";
export default {
  components: {
    Back,
  },
  data() {
    return {
      mobile: "",
      pwd: "",
      checked: false,
    };
  },
  computed: {
    canSubmit() {
      return this.mobile && this.pwd;
    },
  },
  mounted() {
    // 解决移动端不同浏览器的兼容性，有点浏览器会把地址栏的高度算在高度里，所以这里借助js处理
    this.$refs.wrap.style["min-height"] = `${window.innerHeight}px`;
  },
  methods: {
    handleLogin() {
      if (!this.canSubmit) return;
      if (!this.checked) {
        this.$toast("请先阅读并同意相关协议条款");
        return;
      }
      const data = {
        mobile: this.mobile,
        password: md5(this.pwd),
      };
      login(data)
        .then((res) => {
          const { token, user_info } = res;
          setStorage("token", token);
          setStorage("user_info", user_info);
          this.$toast.success("登录成功");
          this.$router.replace("/personal");
        })
        .catch(() => {});
    },
    handle2Register() {
      this.$router.push("/register");
    },
    handle2ForgetPwd() {
      this.$router.push("/forgetPwd");
    },
    handle2Agreement() {
      this.$router.push("/settings/agreement");
    },
    handle2Privacy() {
      this.$router.push("/settings/privacy");
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  /* min-height: 100vh; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    padding: 0 28px;
    .logo {
      width: 173px;
      margin: 34px auto;
      display: block;
    }
    .filedWrap {
      margin-bottom: 20px;
      .label {
        line-height: 20px;
        margin-bottom: 7px;
        padding-left: 16px;
      }
      .field {
        height: 44px;
        line-height: 44px;
        background: #212121;
        border-radius: 27px;
        align-items: center;
        color: #fff;
        input::-webkit-input-placeholder {
          color: #666666;
        }
        :global {
          .van-field__control {
            color: #fff;
          }
        }
      }
    }
    .btn {
      margin-top: 35px;
    }
    .actionWrap {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      color: #a7a7a7;
      line-height: 20px;
      .register {
        color: #54b9e0;
      }
    }
  }
  .clause {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .clauseItem {
      color: #54b9e0;
    }
    .checked {
      :global {
        .van-checkbox__icon,
        .van-checkbox__label {
          font-size: 12px;
          color: #fff;
        }
        .van-icon {
          width: 12px;
          height: 12px;
          font-size: 12px;
        }
        .van-checkbox__icon--checked {
          .van-icon-success {
            border: none;
            background: url("~@/assets/img/checked.png");
            background-size: 100% 100%;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
